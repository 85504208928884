import React, { useState, useEffect } from 'react';
import axios from 'axios';

const personal_access_token = 'HQkTuQlfhOPXyMwKwBuFEwrAbpgDwOFgHlWMulUJ';

const App = () => {
  const [vinylCollection, setVinylCollection] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [apiStats, setApiStats] = useState({
    totalRequests: 0,
    successRequests: 0,
    errorRequests: 0,
  });

  const fetchVinylCollection = async () => {
    setLoading(true);
    try {
      setApiStats((prevStats) => ({
        ...prevStats,
        totalRequests: prevStats.totalRequests + 1,
      }));

      const response = await axios.get('https://api.discogs.com/users/ppilihp/collection/folders/0/releases', {
        headers: {
          'Authorization': `Discogs token=${personal_access_token}`,
          'User-Agent': 'MyVinylLabeler/1.0 +https://myvinylabeler.com'
        },
      });

      const records = response.data.releases.map((release) => ({
        catalogNumber: release.basic_information.labels.map((label) => label.catno).join(', '),
        artist: release.basic_information.artists.map((artist) => artist.name).join(', '),
        title: release.basic_information.title,
        label: release.basic_information.labels.map((label) => label.name).join(', '),
        rating: release.rating,
        released: release.basic_information.year,
        releaseId: release.id,
        collectionFolder: release.folder_id.toString(),
        dateAdded: release.date_added,
        mediaCondition: release.condition && release.condition.media ? release.condition.media : 'No condition',
        origin: release.origin,
        notes: release.notes,
      }));

      records.sort((a, b) => a.artist.localeCompare(b.artist));

      setVinylCollection(records);

      setApiStats((prevStats) => ({
        ...prevStats,
        successRequests: prevStats.successRequests + 1,
      }));
    } catch (error) {
      console.error('Fehler beim Abrufen der Vinyl-Sammlung:', error);
      setApiStats((prevStats) => ({
        ...prevStats,
        errorRequests: prevStats.errorRequests + 1,
      }));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchVinylCollection();
  }, []);

  const selectRecord = (record) => {
    setSelectedRecord(record);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex">
        <div className="w-1/3 pr-4">
          <h2 className="text-lg font-semibold mb-2">Vinyl Collection</h2>
          <ul className="overflow-y-auto max-h-screen">
            {vinylCollection.map((record, index) => (
              <li
                key={record.releaseId}
                onClick={() => selectRecord(record)}
                className="cursor-pointer py-2 px-4 mb-2 hover:bg-gray-200"
              >
                <span className="text-gray-600">{index + 1}. </span>
                <span className="text-gray-800 text-sm">{record.artist}</span>
                <br />
                <span className="text-gray-800 text-xs">{record.title}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="w-2/3">
          {isLoading ? (
            <p>Loading your vinyl collection...</p>
          ) : (
            selectedRecord ? (
              <VinylRecordComponent record={selectedRecord} />
            ) : (
              <p>Select a record from the list to view details.</p>
            )
          )}
        </div>
      </div>

      <div className="mb-4">
        <h2 className="text-lg font-semibold mb-2">API Stats</h2>
        <p>Total Requests: {apiStats.totalRequests}</p>
        <p>Successful Requests: {apiStats.successRequests}</p>
        <p>Error Requests: {apiStats.errorRequests}</p>
      </div>
    </div>
  );
};

const VinylRecordComponent = ({ record }) => {
  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-s font-semibold mb-2">Details for: {record.title}</h2>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">ARTIST</label>
        <p className="text-gray-800 text-2xl">{record.artist}</p>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">TITLE</label>
        <p className="text-gray-800 text-2xl">{record.title}</p>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">LABEL</label>
        <p className="text-gray-800 text-lg">{record.label}</p>
      </div>

      {record.rating !== 0 && (
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">RATING</label>
          <p className="text-gray-800 text-lg">{record.rating}</p>
        </div>
      )}

      {record.released !== 0 && (
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">YEAR OF RELEASE</label>
          <p className="text-gray-800 text-lg">{record.released}</p>
        </div>
      )}
    </div>
  );
};

export default App;
